<template>
  <jc-form :model="form" :rules="rules" ref="form" :label-width="labelWidth" class="jc-form-tiny-width">
    <jc-form-item prop="organizersName" label="主办单位名称：">
      <jc-input v-model.trim="form.organizersName" type="text" placeholder="请输入主办人姓名"></jc-input>
    </jc-form-item>
    <jc-form-item prop="indentityAddress" label="证件地址：" v-if="isShowField('indentityAddress')">
      <jc-input v-model.trim="form.indentityAddress" type="text" placeholder="请输入证件地址"></jc-input>
    </jc-form-item>
    <jc-form-item v-if="isShowField('detailAddress')" prop="detailAddress" label="通信地址：" required>
      <p>
        {{ `${infoData.provinceName}${infoData.cityName}${infoData.countyName}` }}
      </p>
      <jc-input
        v-model.trim="form.detailAddress"
        type="text"
        placeholder="请输入实际通信地址，必须精确到门牌号"
      ></jc-input>
      <p slot="hint">请将通信地址补充完整，通信地址需详细至门牌号</p>
    </jc-form-item>
  </jc-form>
</template>
<script>
import { PRINCIPAL_PERSONAL_RULE } from '@/views/service/rules'
import { AGREEMENT } from '@/views/service/constant'
export default {
  data() {
    return {
      form: {
        organizersName: '', // 主办单位
        indentityAddress: '', // 证件地址
        detailAddress: '', // 通信地址''
        miitRecordPwd: ''
      },
      threeLevelArea: '',
      addressTip: true,
      AGREEMENT,
      rules: PRINCIPAL_PERSONAL_RULE.call(this)
    }
  },
  props: {
    infoData: Object,
    labelWidth: String
  },
  components: {},
  mixins: [],
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject(new Error('submit false'))
          }
        })
      })
    },
    getInit() {
      this.threeLevelArea = this.infoData.fullAddress || ''
      this.form.organizersName = this.infoData.organizersName || ''
      this.form.indentityAddress = this.infoData.indentityAddress || ''
      this.form.detailAddress = this.infoData.detailAddress || ''
      this.form.miitRecordPwd = this.infoData.miitRecordPwd
    }
  },
  mounted() {},
  created() {
    this.getInit()
  },
  watch: {
    infoData() {
      this.getInit()
    }
  },
  computed: {},
  filters: {}
}
</script>
